import { useSpring, easings } from '@react-spring/web';

function useMenuCloseButtonSpring({ visible = false, time = 250 } = {}) {
    const {
        opacity: closeButtonOpacity,
        x: closeButtonX,
        ...closeButton
    } = useSpring({
        config: {
            duration: visible ? 0 : time * 2,
            easing: easings.easeOutBack,
        },
        delay: visible ? time * 0.5 : 0,
        from: { opacity: 0, x: 100 },
        to: { opacity: visible ? 1 : 0, x: visible ? 0 : 100 },
    });

    const style = {
        ...closeButton,
        opacity: closeButtonOpacity,
        transform: closeButtonX.to((value) => `translateX(${value}px)`),
        pointerEvents: closeButtonOpacity.to((v) => (v > 0 ? 'auto' : 'none')),
    };

    return {
        style,
    };
}

export default useMenuCloseButtonSpring;
