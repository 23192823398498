/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'wouter';

import PillButton from '../buttons/PillButton';
import MenuSectionTypeIcon from '../icons/MenuSectionTypeIcon';
import MenuSectionTitle from '../typography/MenuSection';

import styles from '../../styles/lists/menu-section-list.module.css';

const propTypes = {
    title: PropTypes.node,
    link: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.node,
    withoutViewAll: PropTypes.bool,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    itemsClassName: PropTypes.string,
    onClickLink: PropTypes.func,
};

const defaultProps = {
    title: null,
    link: null,
    icon: null,
    children: null,
    withoutViewAll: false,
    className: null,
    titleClassName: null,
    itemsClassName: null,
    onClickLink: null,
};

function MenuSectionList({
    title,
    link,
    icon,
    children,
    withoutViewAll,
    className,
    titleClassName,
    itemsClassName,
    onClickLink,
}) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {title !== null ? (
                <MenuSectionTitle
                    className={classNames([
                        styles.title,
                        {
                            [titleClassName]: titleClassName !== null,
                        },
                    ])}
                >
                    {link !== null ? (
                        <Link href={link} onClick={onClickLink}>
                            {title}
                        </Link>
                    ) : (
                        title
                    )}
                    {icon !== null ? (
                        <MenuSectionTypeIcon type={icon} className={styles.icon} />
                    ) : null}
                </MenuSectionTitle>
            ) : null}
            <div
                className={classNames([
                    styles.items,
                    {
                        [itemsClassName]: itemsClassName !== null,
                    },
                ])}
            >
                {children}
            </div>
            {!withoutViewAll && link !== null ? (
                <div className={styles.viewAll}>
                    <PillButton
                        dark
                        compact
                        href={link}
                        onClick={onClickLink}
                        className={styles.viewAllButton}
                    >
                        <FormattedMessage defaultMessage="Voir tout" description="Button label" />
                    </PillButton>
                </div>
            ) : null}
        </div>
    );
}

MenuSectionList.propTypes = propTypes;
MenuSectionList.defaultProps = defaultProps;

export default MenuSectionList;
