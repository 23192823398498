import { useUser } from '@folklore/auth';
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import useMenuCloseButtonSpring from '../../animations/useMenuCloseButtonSpring';
import AccountButton from '../buttons/AccountButton';
import CloseButton from '../buttons/CloseButton';

import styles from '../../styles/layouts/main-menu-layout.module.css';

const propTypes = {
    withCloseButton: PropTypes.bool,
    closeMenu: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    withCloseButton: false,
    closeMenu: null,
    children: null,
    className: null,
};

function MainMenuLayout({ children, closeMenu, withCloseButton, className }) {
    const url = useUrlGenerator();
    const user = useUser();
    const tracking = useTracking();

    const { style: closeButtonStyle } = useMenuCloseButtonSpring({
        visible: withCloseButton,
    });

    const onClickClose = useCallback(() => {
        if (closeMenu !== null) {
            closeMenu();
        }
        tracking.trackEvent('Navigation', 'click_menu', 'close');
    }, [closeMenu, tracking]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {children}
            <animated.div className={styles.closeButton} style={closeButtonStyle}>
                <AccountButton
                    href={user === null ? url('login') : url('account')}
                    circleColor="#222"
                    className={styles.accountIcon}
                />
                <CloseButton className={styles.closeIcon} onClick={onClickClose} />
            </animated.div>
        </div>
    );
}

MainMenuLayout.propTypes = propTypes;
MainMenuLayout.defaultProps = defaultProps;

export default MainMenuLayout;
